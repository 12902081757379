/* フォントと基本スタイルの設定 */
body {
    font-family: 'Arial', sans-serif;
}
  
.App {
    text-align: center;
    font-family: Arial, sans-serif;
}
  
/* ナビゲーションバーのスタイル */
.App-header {
    background-color: #ffffff; /* 白基調 */
    padding: 0;
    color: black;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
  
.App-nav {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    background-color: #ffffff;
}
  
.App-nav a {
    color: black;
    text-decoration: none;
    padding: 14px 20px;
    margin: 0 10px;
    transition: background-color 0.3s;
}
  
.App-nav a:hover {
    background-color: #f0f0f0;
}
  
/* メインコンテンツのスタイル */
main {
    padding-top: 80px; /* ヘッダーの高さに合わせて調整 */
    padding: 20px;
}
  
section {
    margin: 20px 0;
}
  
/* タイトルのフォントサイズを統一 */
.product-title, .user-voice-title {
    font-size: 2.5rem;
}
  
h2.user-voice-title {
    margin-top: 3rem; /* 余白を追加 */
}
  
/* Recent News のスタイル */
.recent-news-list {
    margin-top: 20px;
}
  
.recent-news-item {
    display: block;
    padding: 12px 0; /* 項目の余白を20％増やす */
    color: black;
    text-decoration: none;
    border-bottom: 1px solid #ddd; /* 下線のみを追加 */
    transition: color 0.3s;
}
  
.recent-news-item:hover {
    color: #007bff; /* ホバー時の色を変更 */
}
  
.recent-news-item .text-muted {
    margin-right: 10px;
}
  
/* タグのスタイル */
.tags {
    display: flex;
    align-items: center;
    justify-content: flex-end; /* タグを右揃えに */
}
  
.badge {
    font-size: 0.8rem;
    padding: 0.3em 0.5em;
}
  
/* フッターのスタイル */
footer {
    background-color: #f1f1f1;
    padding: 10px;
    text-align: center;
    font-size: 0.7em; /* フッターの文字の大きさを元に戻す */
}
  
/* ボタンのスタイル */
.btn-outline-secondary {
    border-color: #6c757d;
    color: #6c757d;
    background-color: transparent;
    display: flex;
    align-items: center;
}
  
.btn-outline-secondary:hover {
    background-color: #6c757d;
    color: white;
}
  
.notification {
    margin: 20px 0;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f9f9f9;
}
  
/* お知らせ一覧のスタイル */
.space-y-10 {
    margin-top: 20px;
}
  
.space-y-10 ul {
    border-top: 1px solid gray;
}
  
.space-y-10 li {
    border-bottom: 1px solid gray;
}
  
.space-y-10 li a {
    display: grid;
    grid-template-columns: 160px 1fr;
    padding: 6px 0;
    text-decoration: none;
    color: black;
    transition: text-decoration 0.3s;
}
  
.space-y-10 li a:hover .group-hover\\:item\\:underline {
    text-decoration: underline;
}
  
.space-y-10 li a p {
    margin: 0;
}
  
.space-y-10 .flex {
    display: flex;
    justify-content: flex-end;
}
  
.space-y-10 .my-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    background-color: #333;
    color: white;
    text-decoration: none;
    border-radius: 4px;
}
  
.space-y-10 .my-button p {
    margin: 0;
}
  
.space-y-10 .my-button svg {
    margin-left: 8px;
    fill: currentColor;
    stroke: currentColor;
}
  
/* ResearchページのGIFコンテナのスタイル */
.gif-container {
    text-align: center;
    margin-top: 20px;
}
  
.large-gif img {
    width: 100%; /* GIFのサイズを大きくする */
}
  
.large-image {
    width: 66.66%; /* 画像のサイズを2/3にする */
}
  
.d-flex {
    display: flex;
}
  
.align-items-center {
    align-items: center;
}
  
.align-self-center {
    align-self: center;
}
  
.ms-3 {
    margin-left: 1rem;
    flex: 2; /* テキストの掲載幅を倍にする */
}
  
/* Videoコンテナのスタイル */
.video-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
  
video {
    width: 100%; /* 動画のサイズを設定 */
    height: auto;
}
  
/* Testimonials */
.testimonials {
    display: flex;
    justify-content: space-between; /* 横並びにするために追加 */
    margin-top: 20px;
}
  
.testimonial {
    flex: 1; /* 各要素を均等に配置 */
    margin: 0 10px; /* 各要素の間に適切な余白を追加 */
    background-color: transparent; /* 背景色なし */
}
  
.testimonial p {
    margin: 0;
}
